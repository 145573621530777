<template>
  <div>
    <router-view></router-view>
    <title-wrapper :title="`${$t('components.manageCompanies.title')} (${count})`">
      <my-data-table
        :data="companies"
        :headers="headers"
        :loading="loading"
        :tableLoading="tableLoading"
        :length="length"
        :page.sync="params.page"
        :sortBy="sortBy"
        :sortDesc="sortDesc"
        :customSort="(items) => items"
        :items-per-page="20"
        @update:sort-by="sortByFunc"
        @update:sort-desc="sortDescFunc"
      >
        <template v-slot:filters>
          <v-col cols="12" sm="2">
            <v-text-field
              v-model="params.companyName"
              hide-details
              :placeholder="$t('helpers.companyName')"
              solo
            ></v-text-field
          ></v-col>
          <v-col cols="12" sm="2">
            <v-text-field
              v-model="params.email"
              hide-details
              :placeholder="$t('helpers.email')"
              solo
            ></v-text-field
          ></v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
              solo
              v-model="params.countryCode"
              :items="mappedCountries"
              :hide-details="true"
              :placeholder="$t('helpers.country')"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field
              v-model="params.nip"
              hide-details
              :placeholder="$t('helpers.registerForm')"
              solo
            ></v-text-field
          ></v-col>
          <v-col cols="12" sm="2">
            <v-menu
              ref="menu"
              v-model="dateMenu"
              :close-on-content-click="false"
              transition="slide-y-transition"
              offset-y
              :nudge-top="-10"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="params.dates"
                  :label="$t('helpers.registerDate')"
                  readonly
                  hide-details
                  :placeholder="$t('helpers.registerDate')"
                  solo
                  v-bind="attrs"
                  v-on="on"
                >
                  <template slot="append">
                    <v-icon small :class="{ active: dateMenu }">$arrowIcon</v-icon>
                  </template></v-text-field
                >
              </template>
              <v-date-picker
                v-model="params.dates"
                :width="$vuetify.breakpoint.smAndDown ? '100%' : '400'"
                no-title
                scrollable
                range
                :first-day-of-week="dayOfWeek"
                :locale="selectedLanguage"
                :min="params.dates ? params.dates[0] : ''"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" sm="1">
            <v-btn color="primary " elevation="0" fab dark block @click="onSearch">
              <v-icon>mdi-magnify</v-icon>
            </v-btn></v-col
          >
          <v-col cols="6" sm="1">
            <v-btn color="primary" elevation="0" fab dark block @click="clearParams">
              <v-icon>mdi-cancel</v-icon>
            </v-btn></v-col
          >
          <v-col cols="12" sm="2" v-if="isSysAdmin">
            <v-select
              v-model="params.salesmanCode"
              hide-details
              :placeholder="$t('helpers.salesmanFilter')"
              :items="salesmanFilters"
              item-value="salesmanCode"
              item-text="fullName"
              solo
            ></v-select
          ></v-col>
        </template>

        <template v-slot:actions="slotProps">
          <router-link :to="{ name: returnRouteName, params: { companyId: slotProps.item._id } }">
            <v-list-item class="hover-item-popup">
              <v-list-item-content> {{ $t('helpers.showDetails') }}</v-list-item-content>
            </v-list-item>
          </router-link>
        </template>

        <template v-slot:below>
          <v-row
            ><v-col><v-divider></v-divider></v-col
          ></v-row>
          <v-row
            ><v-col v-if="isSysAdmin"
              ><primary-button :to="{ name: 'AddCompany' }"
                ><v-icon class="mr-4">mdi-plus-circle-outline</v-icon
                >{{ $t('components.manageCompanies.addCompany') }}</primary-button
              ></v-col
            ><v-col v-if="isSysAdmin" class="text-right"
              ><primary-button :on-click="downloadCompanyCSV" :loading="csvCompanyLoading">{{
                $t('components.systemSettings.downloadCompanyCsv')
              }}</primary-button></v-col
            ></v-row
          >
        </template>
      </my-data-table>
    </title-wrapper>
  </div>
</template>
<script>
import PrimaryButton from '../../../../components/buttons/PrimaryButton.vue';
import MyDataTable from '../../../../components/MyDataTable.vue';
import TitleWrapper from '../../../../components/TitleWrapper.vue';
import SysadminService from '../../../../services/SysadminService';
import { sortHandler } from '../../../../mixins/sortHandler';
import { countriesCodeHandler } from '../../../../mixins/countriesCodeHandler';
import VueI18n from '../../../../plugins/i18n';
import { permissionRolesMixin } from '../../../../mixins/permissionRolesMixin';
import SalesmanService from '../../../../services/SalesmanService';
import { dateHandler } from '../../../../mixins/dateHandler';
import exportFromJSON from 'export-from-json';

export default {
  components: { TitleWrapper, MyDataTable, PrimaryButton },
  mixins: [sortHandler, countriesCodeHandler, permissionRolesMixin, dateHandler],
  name: 'Companies',
  data() {
    return {
      count: 0,
      itemsPerPage: 20,
      length: 0,
      companies: [],
      salesmanFilters: [],
      loading: true,
      tableLoading: false,
      dateMenu: false,
      dayOfWeek: 1,
      params: {
        email: '',
        nip: '',
        companyName: '',
        sort: '',
        page: 1,
        limit: 20,
        countryCode: '',
        salesmanCode: '',
        dates: [],
        csv: false,
      },
      salesmanHeaders: [
        { text: VueI18n.t('helpers.companyName'), value: 'namePlusEmployees' },
        { text: VueI18n.t('helpers.email'), value: 'users[0].email', sortable: false },
        { text: VueI18n.t('helpers.country'), value: 'countryCode' },
        { text: VueI18n.t('helpers.registerForm'), value: 'nip' },
        { text: VueI18n.t('helpers.lastActive'), value: 'lastSeenUser.lastActive' },
        { text: VueI18n.t('helpers.registerDate'), value: 'createdAt' },
        { text: '', value: 'actions', sortable: false },
      ],
      sysadminHeaders: [
        { text: VueI18n.t('helpers.companyName'), value: 'namePlusEmployees' },
        { text: VueI18n.t('helpers.email'), value: 'users[0].email', sortable: false },
        { text: VueI18n.t('helpers.country'), value: 'countryCode' },
        { text: VueI18n.t('helpers.registerForm'), value: 'nip' },
        { text: VueI18n.t('helpers.salesmanFilter'), value: 'salesmanFullName' },
        { text: VueI18n.t('helpers.lastActive'), value: 'lastSeenUser.lastActive' },
        { text: VueI18n.t('helpers.registerDate'), value: 'createdAt' },
        {
          text: VueI18n.t('helpers.statementAcceptanceDate'),
          value: 'lastSeenUser.statementAcceptanceDate',
        },
        { text: '', value: 'actions', sortable: false },
      ],
      csvCompanyLoading: false,
    };
  },
  computed: {
    returnRouteName() {
      return this.isSalesman ? 'SalesmanSingleCompany' : 'SingleCompany';
    },
    headers() {
      return this.isSalesman ? this.salesmanHeaders : this.sysadminHeaders;
    },
  },
  watch: {
    'params.page': function () {
      this.getFiltered();
    },
  },
  methods: {
    async downloadCompanyCSV() {
      this.csvCompanyLoading = true;
      this.params.csv = true;
      const response = await SysadminService.getAllCompanies(this.mapParams(this.params));
      this.params.csv = false;
      const fileName = 'firmy';
      const exportType = exportFromJSON.types.csv;
      const data = response.data;

      if (data) {
        exportFromJSON({ data, fileName, exportType, withBOM: true });
      }

      this.csvCompanyLoading = false;
    },
    async getSalesmenForFilters() {
      const response = await SalesmanService.getSalesmenForFilters();

      this.salesmanFilters = response.data;
    },
    updatePage(page) {
      this.page = page;
    },
    clearParams() {
      this.params = {
        email: '',
        nip: '',
        companyName: '',
        sort: '',
        page: 1,
        limit: 20,
        countryCode: '',
        salesmanCode: '',
        dates: [],
        dateFrom: '',
        dateTo: '',
        csv: false,
      };
      this.getFiltered();
    },
    mapParams() {
      this.sortDates();

      const params = {
        email: this.params.email,
        nip: this.params.nip,
        companyName: this.params.companyName,
        sort: this.params.sort,
        page: this.params.page,
        limit: this.params.limit,
        countryCode: this.params.countryCode,
        salesmanCode: this.params.salesmanCode,
        dateFrom: this.params.dateFrom,
        dateTo: this.params.dateTo,
        csv: this.params.csv,
      };
      return params;
    },
    onSearch() {
      this.params.page = 1;
      this.getFiltered();
    },
    async getFiltered() {
      try {
        this.tableLoading = true;
        let response;

        if (this.isSysAdmin) {
          response = await SysadminService.getAllCompanies(this.mapParams(this.params));
        }
        if (this.isSalesman) {
          response = await SalesmanService.getCompanies(this.mapParams(this.params));
        }

        this.companies = response.data.map((company) => {
          company.namePlusEmployees = `${company.companyName} (${company.users.length})`;
          return company;
        });
        this.count = response.count;
        this.length = Math.ceil(response.count / this.itemsPerPage);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
        this.tableLoading = false;
      }
    },
    fetchSort() {
      this.getFiltered();
    },
  },

  created() {
    this.getSalesmenForFilters();
    this.getFiltered();
  },
};
</script>
<style lang="scss" scoped>
.text-right {
  text-align: right;
}
</style>
