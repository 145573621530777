<template>
  <title-link-wrapper :to="{ name: 'ManageCompanies' }" :title="$t('components.addCompany.title')">
    <v-container class="white pa-8">
      <v-form v-model="isFormValid" ref="form">
        <v-row>
          <v-col class="py-0" col="12">
            <h2 class="text font-weight-bold text-color">{{ $t('helpers.country') }}</h2>
            <v-autocomplete
              solo
              outlined
              v-model="countryCode"
              :items="mappedCountries"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0" cols="12" md="8">
            <h2 class="text mb-2">{{ $t('helpers.registerForm') }}</h2>

            <v-text-field
              v-model="nip"
              :rules="foreignCountry ? validators.foreignNipRules : validators.nipRules"
              :label="$t('helpers.registerForm')"
              persistent-hint
              outlined
              solo
              elavation="0"
            ></v-text-field>
          </v-col>
          <v-col class="py-0 my-auto" cols="12" md="4">
            <primary-button
              :color="foreignCountry ? 'grey lighten-2' : 'primary'"
              class="mb-4 mb-md-0"
              :onClick="loadCompanyDataByNip"
              :disabled="!validNip || foreignCountry"
              :loading="nipLoading"
              >{{ $t('components.addCompany.downloadData') }}</primary-button
            >
          </v-col>
        </v-row>
        <v-row class="mb-7">
          <v-col class="py-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0" cols="12">
            <h2 class="text mb-2">{{ $t('helpers.companyName') }}</h2>

            <v-text-field
              v-model="companyName"
              :rules="validators.requiredRules"
              :label="$t('helpers.companyName')"
              outlined
              solo
              elavation="0"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0" cols="12">
            <h2 class="text mb-2">{{ $t('components.addCompany.companyAddress') }}</h2>

            <v-text-field
              v-model="address"
              :rules="validators.requiredRules"
              :label="$t('components.addCompany.companyAddress')"
              outlined
              solo
              elavation="0"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0" cols="12" md="6">
            <h2 class="text mb-2">{{ $t('helpers.zipCode') }}</h2>

            <v-text-field
              v-model="zipCode"
              :rules="foreignCountry ? [] : validators.zipCodeRules"
              :label="$t('helpers.zipCode')"
              outlined
              solo
              elavation="0"
            ></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="6">
            <h2 class="text mb-2">{{ $t('helpers.city') }}</h2>

            <v-text-field
              v-model="city"
              :rules="validators.requiredRules"
              :label="$t('helpers.city')"
              outlined
              solo
              elavation="0"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0" cols="12" md="6">
            <h2 class="text mb-2">{{ $t('components.addCompany.referralCode') }}</h2>

            <v-text-field
              v-model="referralCode"
              :label="$t('components.addCompany.referralCode')"
              outlined
              solo
              elavation="0"
            ></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="6">
            <h2 class="text mb-2">{{ $t('helpers.phoneNumber') }}</h2>

            <v-text-field
              v-model="phone"
              :label="$t('helpers.phoneNumber')"
              :rules="validators.phoneRules"
              outlined
              solo
              elavation="0"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col><v-divider></v-divider></v-col>
        </v-row>
        <v-row>
          <v-col
            ><primary-button :onClick="addCompany"
              ><v-icon class="mr-4">mdi-plus-circle-outline</v-icon
              >{{ $t('components.addCompany.addCompany') }}</primary-button
            ></v-col
          >
        </v-row>
      </v-form>
    </v-container>
  </title-link-wrapper>
</template>
<script>
import TitleLinkWrapper from '../../../../components/TitleLinkWrapper.vue';
import PrimaryButton from '../../../../components/buttons/PrimaryButton.vue';
import CompanyService from '../../../../services/CompanyService';
import NipService from '../../../../services/NipService';
import { validators, isValidNip } from '../../../../validators/Validators';
import { countriesCodeHandler } from '../../../../mixins/countriesCodeHandler';
import { formMixin } from '../../../../mixins/formMixin';

export default {
  components: { TitleLinkWrapper, PrimaryButton },
  mixins: [countriesCodeHandler, formMixin],
  data() {
    return {
      isFormValid: false,
      nip: '',
      companyName: '',
      address: '',
      zipCode: '',
      city: '',
      referralCode: '',
      phone: '',
      loading: false,
      nipLoading: false,
      validNip: false,
    };
  },
  methods: {
    async addCompany() {
      this.$refs.form.validate();
      if (this.isFormValid) {
        try {
          this.loading = true;
          const company = {
            nip: this.nip,
            companyName: this.companyName,
            address: this.address,
            zipCode: this.zipCode,
            city: this.city,
            referralCode: this.referralCode,
            phone: this.phone,
            verified: true,
            countryCode: this.countryCode,
          };
          const response = await CompanyService.add(company);
          this.$toasted.global.success({
            message: this.$i18n.t('components.addCompany.addCompanyFunc'),
          });

          this.$router.push({
            name: 'SingleCompany',
            params: { companyId: response.data._id },
          });
        } catch (err) {
          console.error(err);
        } finally {
          this.loading = false;
        }
      }
    },
    async loadCompanyDataByNip() {
      try {
        this.nipLoading = true;
        const companyData = await NipService.getDataByNip(this.nip);
        this.companyName = companyData.name;
        this.address = companyData.street;
        this.zipCode = companyData.zipCode;
        this.city = companyData.city;
        this.$toasted.global.success({
          message: this.$i18n.t('components.addCompany.loadCompanyDataByNipFunc'),
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.nipLoading = false;
      }
    },
  },
  computed: {
    validators() {
      return validators;
    },
  },
  watch: {
    nip: function (val) {
      this.validNip = isValidNip(val);
    },
  },
};
</script>
<style lang="scss" scoped></style>
